@import 'assets/sass/atlantis/variables';

body {
  overflow: hidden;
}

small {
  font-size: 11px;
}

.alert {
  border-radius: 5px;
  margin-bottom: 30px;
}
.card-body,
.modal-body {
  .alert-danger {
    background: rgba($danger-color, 0.2);
  }
  .alert-warning {
    background: rgba($warning-color, 0.2);
  }
  .alert-success {
    background: rgba($green-color, 0.2);
  }
  .alert-info {
    background: rgba($info-color, 0.05);
  }
  .alert-default {
    background: rgba($dark-color, 0.2);
  }
  .alert-primary {
    background: rgba($primary2-color, 0.2);
  }
}

.badge.badge-count.badge-danger {
  background: transparent;
  border-color: rgba($danger-color, 1);
  color: rgba($danger-color, 1);
}
.badge.badge-count.badge-warning {
  background: transparent;
  border-color: rgba($warning-color, 1);
  color: rgba($warning-color, 1) !important;
}
.badge.badge-count.badge-success {
  background: transparent;
  border-color: rgba($green-color, 1);
  color: rgba($green-color, 1);
}
.badge.badge-count.badge-info {
  background: transparent;
  border-color: rgba($info-color, 0.8);
  color: rgba($info-color, 1);
}
.badge.badge-count.badge-default {
  background: transparent;
  border-color: rgba($dark-color, 1);
  color: rgba($dark-color, 1);
}
.badge.badge-count.badge-primary {
  background: transparent;
  border-color: rgba($primary2-color, 1);
  color: rgba($primary2-color, 1);
}
.badge-primary:focus,
.badge-primary:hover {
  color: #fff !important;
  background-color: #0062cc !important;
}
.badge-secondary:focus,
.badge-secondary:hover {
  color: #fff !important;
  background-color: #545b62 !important;
}
.badge-success:focus,
.badge-success:hover {
  color: #fff !important;
  background-color: #1e7e34 !important;
}
.badge-info:focus,
.badge-info:hover {
  color: #fff !important;
  background-color: $info-color !important;
}
.badge-warning:focus,
.badge-warning:hover,
.badge.badge-count.badge-warning:hover {
  color: #fff !important;
  background-color: $warning-color !important;
}
.badge-danger:focus,
.badge-danger:hover {
  color: #fff !important;
  background-color: #bd2130 !important;
}
.badge-light:focus,
.badge-light:hover {
  color: #212529 !important;
  background-color: #dae0e5 !important;
}
.badge-dark:focus,
.badge-dark:hover {
  color: #fff !important;
  background-color: #1d2124 !important;
}

.modal-header .fa-sm {
  font-size: 1em;
}

.main-panel > .content {
  overflow: auto;
  height: calc(100% - 61px);
}

.page-inner {
  min-height: calc(100% - 69px);
}

.footer {
  position: relative;
}

.logo-header {
  padding-right: 50px;
}
.logo-header .logo .navbar-brand {
  max-width: 100%;
  max-height: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
}
@media screen and (max-width: 991px) {
  .logo-header .logo .navbar-brand {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .sidebar_minimize_hover .logo-header .nav-toggle {
    right: 0px !important;
  }
}

a {
  cursor: pointer;
}

.table td,
.table th {
  height: auto;
  padding: 8px !important;
}
.table .cell-commands .btn-sm {
  padding: 7px !important;
}
.table-sm td,
.table-sm th {
  padding: 5px !important;
}

.form-group .progress-card {
  margin-top: -10px !important;
}

pbw-button + pbw-button {
  margin-left: 0.5rem !important;
}
.btn-table pbw-button {
  margin-left: -1px !important;
}

[class*='col-'] > pbw-button,
[class*='col-'] > pbw-button .btn {
  display: block;
  width: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #a5a5a5;
}
::-webkit-scrollbar-track {
  border-radius: 6px;
  background: #f1f1f1;
}

.form-group,
.form-check {
  padding: 10px 0;
}

.form-group label,
.form-check label {
  margin-bottom: 0.2rem;
}

.k-widget.form-control {
  padding: 0;
}
.k-datepicker,
.k-dropdownlist,
.k-multiselect,
.k-combobox,
.k-dropdownlist .k-button,
.k-multiselect .k-button,
.k-combobox .k-button {
  border-color: #ebedf2 !important;
  font-size: 14px !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: transparent !important;
  .k-input-inner {
    min-height: 40px;
  }
}
.k-dropdownlist,
.k-combobox {
  padding-left: 8px !important;
}
.k-datepicker.k-focus,
.k-combobox.k-focus,
.k-dropdownlist.k-focus,
.k-multiselect.k-focus {
  border-color: #3e93ff !important;
  box-shadow: none !important;
}
.k-dropdownlist[readonly='false'] {
  opacity: 1 !important;
  background: transparent !important;
  border-color: #ebedf2 !important;
}

.k-multiselect {
  min-height: 42px;
}
.k-multiselect .k-button {
  height: 28px;
}
.k-multiselect .k-searchbar {
  float: left;
  width: auto;
}
.k-multiselect .k-chip {
  font-size: 14px;
  border: 1px solid #e4e7eb;
  background-color: #e4e7eb;
  border-radius: 0.25rem;
  padding: 0.375rem 0.375rem;

  .k-icon {
    color: #495057 !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
    margin-left: 5px;
  }
  .k-icon::before {
    content: '\e11b';
  }
}
.k-datepicker,
.k-datetimepicker {
  width: 100% !important;
}
.k-widget:not(.k-multiselect):not(.k-editor),
.k-multiselect .k-input {
  border-width: 0 !important;
}

.k-chart-tooltip-wrapper {
  .k-popup {
    padding: 0 !important;
    background-color: #177dffc2 !important;
  }
  .k-chart-tooltip {
    padding: 0 10px !important;
    background-color: transparent !important;
    color: #ffffff !important;
  }
}

.card .separator-dashed,
.separator-dashed,
.card .separator-solid,
.separator-solid,
.card .separator-dot,
.separator-dot {
  border-top: 1px dashed #c3c3c3;
  margin: 15px 0;
  width: 100%;
}

.border-left {
  border-left: 1px solid #ebecec;
}
.border-right {
  border-left: 1px solid #ebecec;
}

.is-loading {
  * {
    opacity: 0.8 !important;
  }
}

.text-long label + div {
  max-height: 250px;
  overflow-y: auto;
}

.avatar-group mpm-image + mpm-image .avatar {
  margin-left: -0.75rem;
}
.avatar-group mpm-image + mpm-image .avatar-xs {
  margin-left: -0.40625rem;
}
.avatar-group mpm-image + mpm-image .avatar-sm {
  margin-left: -0.625rem;
}
.avatar-group mpm-image + mpm-image .avatar-lg {
  margin-left: -1rem;
}
.avatar-group mpm-image + mpm-image .avatar-xl {
  margin-left: -1.28125rem;
}

.blinker {
  animation: blinker 2s linear 5;
}
.blinker-10 {
  animation: blinker 2s linear 10;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.show-animation {
  animation: show 0.8s linear;
}
@keyframes show {
  0% {
    opacity: 0;
    height: 0;
    padding: 0;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}

@media (min-width: 1800px) {
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}

.badge-count {
  border-radius: 100%;
  margin: 0 2px;
  padding: 5px;
  width: 25px;
  height: 25px;
}
